<template>
  <div class="container">
    <div class="row justify-content-end mb-2">
      <div class="col-sm-3">
        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="outline-success" @click="goToAddPage">Add
          Script</b-button>
      </div>
    </div>
    <b-card>
      <div class="row">
        <b-modal id="modal-danger" ok-variant="danger" ok-title="Confirm" modal-class="modal-danger" centered
          title="Delete" @ok="deleteScript">
          <b-card-text>
            Are you sure you want to delete this Script?
          </b-card-text>
        </b-modal>
        <div class="col-12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="marketplaceData"
            :fields="fields" :sort-by.sync="sortBy">
            <template #cell(index)="data">{{ data.index + 1 }}</template>
            <template v-slot:cell(image_url)="data">
              <img :src="data.item.image_url" alt="Image" height="50" />
            </template>
            <template v-slot:cell(description)="data">
              <p> {{ data.item.description.substring(0, 28) }} ... </p>
            </template>
            <template #cell(action)="data">
              <b-button-group>
                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Update" @click="getUpdateLink(data.item.id)">
                  <feather-icon icon="EditIcon" size="20" />
                </b-button>

                <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Show Script">
                  <b-link @click="getDetailLink(data.item.id)" target="_blank"> <feather-icon icon="EyeIcon"
                      size="20" /></b-link>
                </b-button>

                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Delete" @click="setDeleteId(data.item.id)">
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BContainer,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BLink,
  BButton,
  BModal,
  VBModal,
  BFormFile,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import router from "@/router";

export default {
  components: {
    BTable,
    BFormTextarea,
    BAvatar,
    BLink,
    'b-tooltip': VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormFile,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    VBModal,
    BCardText,
    BContainer,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      scriptsData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 100],
      file: null,
      scriptToDelete: null,
      marketplaceData: [],
      user_token: null,
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "image_url",
          label: "Icon",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "downloads",
          label: "Downloads",
          sortable: true,
        },
        "Action",

      ],
      sortBy: 'downloads',
    }
  },
  computed: {

  },
  mounted() {
    this.getListCodes();
    this.user_token = JSON.parse(localStorage.getItem("accessToken"));

  },
  created() {
  },
  methods: {
    getDetailLink(id) {
      router.push({ name: "adminMarketplaceDetail", params: { id } });
    },
    getUpdateLink(parameter) {
      router.push({ name: "adminMarketplaceUpdate", params: { id: parameter } });
    },
    setDeleteId(id) {
      this.scriptToDelete = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteScript() {
      var id = this.scriptToDelete;
      axios
        .post("/delete-mrktp-script", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.getListCodes();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },

    goToAddPage() {
      router.push({ name: "adminMarketplaceCreate" });
    },

    getListCodes() {
      axios.post('/get-mrktp-scripts')
        .then((response) => {
          this.marketplaceData = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        })
    },


  },
  watch: {

  },
}
</script>

<style></style>